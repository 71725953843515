import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { start } from 'repl';

import {
  getGirlLampStory,
  getHighChanceModeBackground,
  getNullPatlamp,
  getPatlampTiming,
  QuinTiming,
} from '../anticipation';
import { ISongs, mappedAudioSprites, SlotId } from '../config';
import {
  BetBonusReward,
  BetReward,
  EventTypes,
  GameMode,
  GirlLampType,
  ISettledBet,
  PatLampAnimType,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setCurrentHCM,
  setFreeSpinsTotalWin,
  setGameMode,
  setGirlLampStory,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import {
  getUserBonuses,
  isStoppedGql,
  ReelSetType,
  slotBetGql,
} from '../gql/query';
import {
  formatNumber,
  getGameModeByBonusId,
  getGameModeByReelSetId,
  getSpinResult3x3,
  hasCZ1Reel,
  isFreeSpinsMode,
  isHighChanceMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Animator from './animations/animator';
import Tween from './animations/tween';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import {
  ANNOUNCE_ANIMATION_DURATION,
  ANTICIPATION_ENABLE,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  APPLICATION_TRANSPARENT,
  BONUS_SYMBOLS_ID,
  CHERRY_SYMBOLS_ID,
  DOUBLE_CHERRY_FAKE_FADE_IN_DURATION,
  DOUBLE_CHERRY_FAKE_FADE_OUT_DURATION,
  eventManager,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  HIGH_CHANCE_MODE_FADE_IN_DURATION,
  HIGH_CHANCE_MODE_FADE_OUT_DURATION,
  REELS_AMOUNT,
  SlotMachineState,
} from './config';
import { Icon, ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsStopDisplayContainer } from './winAnimations/slotsStopDisplayContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotData;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public animator: Animator;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public winSlotsContainer: WinSlotsContainer;

  public slotStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      transparent: APPLICATION_TRANSPARENT,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.animator = new Animator(this.application);
    this.slotConfig = slotData;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setPrevReelsPosition(startPosition.slice(0, 3));
    const reelSet = setUserLastBetResult().id
      ? slotData.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
      : slotData.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    this.winSlotsContainer = new WinSlotsContainer();
    const spinResult = getSpinResult3x3({
      reelPositions: startPosition.slice(0, 3),
      reelSet,
      icons: slotData.icons,
    });
    this.slotStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      slotStopDisplayContainer: this.slotStopDisplayContainer,
      winSlotsContainer: this.winSlotsContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    } else if (setCurrentHCM().isActive) {
      const hcm = setCurrentHCM();
      const mode = getGameModeByBonusId(hcm.bonusList[0].bonus.id);
      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode,
        background: hcm.background,
      });
      eventManager.emit(
        EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT,
        hcm.totalRounds,
        hcm.currentRound,
        true,
      );
      setGameMode(mode);
    }
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonus.id);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    // eventManager.emit(
    //   EventTypes.UPDATE_TOTAL_WIN_VALUE,
    //   setCurrentFreeSpinsTotalWin(),
    // );
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (setProgress().wasLoaded || setSkipIntroScreen()) {
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        this.setState(SlotMachineState.IDLE);
      });
    }
  }

  private initListeners(): void {
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REGISTER_ANIMATOR,
      this.registerAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_ANIMATOR,
      this.removeAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioHowl.play({ type: ISongs.BigWin_Loop });
      }
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find(
        (reels) => reels.id === settings.reelSetId,
      );
      const spinResult = getSpinResult3x3({
        reelPositions: settings.reelPositions.slice(0, 3),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    }
    setPrevReelsPosition(settings.reelPositions.slice(0, 3));
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR || isHighChanceMode(settings.mode)) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
      if (previousGameMode === GameMode.FREE_SPINS) {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(
            setCurrency(),
            normalizeCoins(setCurrentFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          ),
        );
      } else {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      }
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
      }

      setCurrentBonus({ ...bonus, totalRounds: 0 });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitleText'),
        spins: bonus.rounds,
        currentSpin: 0,
      });

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('freeSpinsTitle', { rounds: bonus.rounds }),
          description: i18n.t('freeSpinsText'),
          btnText: i18n.t('startText'),
          callback: () => {
            setCurrentBonus({
              ...bonus,
              isActive: true,
              totalRounds: setCurrentBonus().totalRounds,
              currentRound: 0,
            });
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        setCurrentBonus({
          ...bonus,
          isActive: true,
          totalRounds: 0,
          currentRound: 0,
        });
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private setPatLampAnticipation(): void {
    const freespinsBonus = this.getFreeSpinBonus();
    if (freespinsBonus) {
      const timing = getPatlampTiming(freespinsBonus.id, freespinsBonus.rounds);
      switch (timing) {
        case QuinTiming.START:
          eventManager.emit(EventTypes.PATLAMP_ANIMATION, PatLampAnimType.X1);
          break;
        case QuinTiming.QQQ:
          eventManager.emit(EventTypes.PATLAMP_ANIMATION, PatLampAnimType.LONG);
          break;
        case QuinTiming.REEL1_STOP:
        case QuinTiming.REEL2_STOP:
          this.reelsContainer.reels[timing - 2].spinAnimation
            ?.getEnding()
            .addOnComplete(() => {
              eventManager.emit(
                EventTypes.PATLAMP_ANIMATION,
                PatLampAnimType.X1,
              );
            });
          break;
        default:
          break;
      }
    }
  }

  private startHighChanceMode(): void {
    const hcm = setCurrentHCM();
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: getGameModeByBonusId(hcm.bonusList[0].bonus.id),
      reelSetId: setReelSetId(),
      fadeOutDuration: HIGH_CHANCE_MODE_FADE_OUT_DURATION,
      fadeInDuration: HIGH_CHANCE_MODE_FADE_IN_DURATION,
      reelPositions: this.nextResult!.bet.result.reelPositions,
      background: hcm.background,
    });
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT,
      hcm.totalRounds,
      hcm.currentRound,
      true,
    );
  }

  private endHighChanceMode(): void {
    setIsFreeSpinsWin(true);
    setCurrentHCM({
      ...setCurrentHCM(),
      isActive: false,
    });
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.REGULAR,
      reelSetId: setReelSetId(),
      fadeOutDuration: HIGH_CHANCE_MODE_FADE_OUT_DURATION,
      fadeInDuration: HIGH_CHANCE_MODE_FADE_IN_DURATION,
      reelPositions: this.nextResult!.bet.result.reelPositions,
    });
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: [1, 1, 1],
      reelSetId: reelSets[GameMode.FREE_SPINS],
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
  }

  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = res.data.userBonuses[0];
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      reelSetId: bet.data.bet.reelSetId,
    };

    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      const hcm = setCurrentHCM();
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: getGameModeByBonusId(hcm.bonusList[0]?.bonus.id),
        reelSetId,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions,
        background: hcm.background,
      });
      eventManager.emit(
        EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT,
        hcm.totalRounds,
        hcm.currentRound,
        true,
      );
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });
    const { bonusStreakOrdinal } = setCurrentBonus().data as {
      bonusStreakOrdinal: string;
    };
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        bonusStreak: bonusStreakOrdinal,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('freeSpinsTotalWinTitle'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )}`,
        bonusStreak: bonusStreakOrdinal,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('freeSpinsTotalWinTitle'),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private registerAnimator(animator: () => void, priority?: number) {
    if (priority !== undefined) {
      this.application.ticker.add(animator, undefined, priority);
    } else {
      this.application.ticker.add(animator);
    }
  }

  private removeAnimator(animator: () => void) {
    this.application.ticker.remove(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total,
      false,
    );
  }

  private updateHighChanceMode(): void {
    const hcm = setCurrentHCM();
    const currUserBonusId = hcm.bonusList[0].id;

    hcm.currentRound += 1;
    hcm.bonusList = hcm.bonusList.slice(1);
    setCurrentHCM(hcm);
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT,
      hcm.totalRounds,
      hcm.currentRound,
      false,
    );
    if (!this.getFreeSpinBonus()) {
      const nullPat = getNullPatlamp(currUserBonusId, hasCZ1Reel(hcm));
      if (nullPat) {
        eventManager.emit(EventTypes.PATLAMP_ANIMATION, PatLampAnimType.NULL);
      }
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isFreeSpinsMode(setGameMode())) {
            this.updateFreeSpinsAmount(
              setCurrentBonus().currentRound,
              setCurrentBonus().rounds,
            );
            if (setGirlLampStory()[setCurrentBonus().currentRound - 1]) {
              eventManager.emit(
                EventTypes.GIRL_LAMP_ANIMATION,
                GirlLampType.IDLE_LIGHT,
              );
            }
          } else {
            this.setPatLampAnticipation();
          }
          if (isHighChanceMode(setGameMode())) {
            this.updateHighChanceMode();
          }
          this.removeErrorHandler();
          this.dynamicReelSetChange();
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(
              this.nextResult.bet.result.spinResult,
            ),
            this.getCherryAnticipationReelId(
              this.nextResult.bet.result.spinResult,
            ),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinsMode(setGameMode()) && !!isTurboSpin,
      );

      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        bonus.totalRounds += 1;
        setCurrentBonus(bonus);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation =
        reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(
                setCurrentBonus().currentRound,
                setCurrentBonus().rounds,
              );
              if (setGirlLampStory()[setCurrentBonus().currentRound - 1]) {
                eventManager.emit(
                  EventTypes.GIRL_LAMP_ANIMATION,
                  GirlLampType.IDLE_LIGHT,
                );
              }
            } else {
              this.setPatLampAnticipation();
            }
            if (isHighChanceMode(setGameMode())) {
              this.updateHighChanceMode();
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(
                this.nextResult.bet.result.spinResult,
              ),
              this.getAnticipationStartReelId(
                this.nextResult.bet.result.spinResult,
              ),
              this.getCherryAnticipationReelId(
                this.nextResult.bet.result.spinResult,
              ),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private getHighChanceModeBonuses(): UserBonus[] {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';

    return this.nextResult!.rewards.filter(
      (reward): reward is BetBonusReward =>
        isBonusReward(reward) && reward.userBonus?.bonus.type === 'GAME_MODE',
    )
      .map((reward) => reward.userBonus!)
      .sort((a, b) => {
        if (a.id < b.id) return -1;
        else if (a.id > b.id) return 1;
        else return 0;
      });
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const highChanceModeBonuses = this.getHighChanceModeBonuses();
    const mode = setGameMode();
    if (freeSpinsBonus) {
      if (!isFreeSpinsMode(mode)) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setGirlLampStory(
          getGirlLampStory(
            freeSpinsBonus.id,
            highChanceModeBonuses.length > 4
              ? highChanceModeBonuses[0].bonus.reelSetId ===
                  reelSets[GameMode.HCM_MODE_G1]
              : false,
          ),
        );
        setCurrentHCM({
          isActive: true,
          activateChecked: true,
          background:
            highChanceModeBonuses.length > 4
              ? getHighChanceModeBackground(
                  highChanceModeBonuses[4].id,
                  getGameModeByBonusId(highChanceModeBonuses[4].bonus.id),
                )
              : 'dusk',
          currentRound: 0,
          totalRounds: 5,
          bonusList: highChanceModeBonuses,
        });
        this.startFreeSpins();
        this.setState(SlotMachineState.IDLE);
      } else {
      }
    } else if (highChanceModeBonuses.length > 0) {
      setCurrentHCM({
        isActive: true,
        activateChecked: true,
        background: 'dusk',
        currentRound: 0,
        totalRounds: 5,
        bonusList: highChanceModeBonuses,
      });
      this.startHighChanceMode();
      this.setState(SlotMachineState.IDLE);
    } else if (
      this.calcMaxNumberOfCherry(this.nextResult!.bet.result.spinResult) === 2
    ) {
      eventManager.emit(EventTypes.START_FADE_ANTICIPATION, {
        fadeOutDuration: DOUBLE_CHERRY_FAKE_FADE_OUT_DURATION,
        fadeInDuration: DOUBLE_CHERRY_FAKE_FADE_IN_DURATION,
        callback: () => {
          setWinAmount(this.nextResult?.bet.result.winCoinAmount);
          setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
          this.setState(SlotMachineState.IDLE);
        },
      });
    } else {
      if (mode === GameMode.REGULAR || isHighChanceMode(mode)) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      }
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(
          setCurrentFreeSpinsTotalWin() +
            this.nextResult!.bet.result.winCoinAmount,
        );
        if (setCurrentFreeSpinsTotalWin()) {
          eventManager.emit(
            EventTypes.UPDATE_TOTAL_WIN_VALUE,
            setCurrentFreeSpinsTotalWin(),
          );
        }
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === reelSets[setGameMode()],
        )!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationStartReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;

    const lineResult = this.slotConfig.lines.map((line) =>
      line.map((v) => spinResult[v]),
    );

    ANTICIPATION_SYMBOLS_ID.forEach((symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
      if (
        lineResult.some((line) =>
          line.slice(0, count).every((icon) => icon.id === symbolId),
        )
      ) {
        minReelId = Math.min(minReelId, count - 1);
      }
    });
    return minReelId;
  }

  private getCherryAnticipationReelId(
    spinResult: Array<Icon>,
  ): [number, number] {
    const lineResult = this.slotConfig.lines.map((line) =>
      line.map((v) => spinResult[v]),
    );

    const count = lineResult.reduce((max, line) => {
      let count = 0;
      for (const icon of line) {
        if (icon.id === SlotId.G) {
          count += 1;
        } else {
          break;
        }
      }
      return Math.max(count, max);
    }, 0);

    const startId = count > 0 ? 0 : REELS_AMOUNT;
    const endId = count;

    return [startId, endId];
  }

  private getStopSoundSymbolCount(spinResult: Array<Icon>): Array<number> {
    let reelStopSound: number[];
    if (isFreeSpinsMode(setGameMode())) {
      reelStopSound = this.getFreeSpinSymbolSoundId(spinResult);
    } else {
      reelStopSound = this.calcStopSoundSymbolCount(
        spinResult,
        1,
        BONUS_SYMBOLS_ID,
      );

      if (reelStopSound.every((current) => current === 0)) {
        reelStopSound = this.calcStopSoundSymbolCount(
          spinResult,
          4,
          CHERRY_SYMBOLS_ID,
        );
      }
    }
    return reelStopSound;
  }

  private getFreeSpinSymbolSoundId(spinResult: Array<Icon>): Array<number> {
    const soundIds = [0, 0, 0];
    const line = spinResult.slice(3, 6);

    for (let index = 0; index < line.length; index++) {
      const symbol = line[index];
      if (symbol.id === SlotId.GC) {
        soundIds[index] = index + 1;
      } else {
        break;
      }
    }

    return soundIds;
  }

  private calcStopSoundSymbolCount(
    spinResult: Array<Icon>,
    cal: number,
    symbol: Array<SlotId>[],
  ): Array<number> {
    const iSoundCnt = [0, 0, 0];

    this.slotConfig.lines.forEach((line) => {
      symbol.forEach((slot) => {
        for (let x = 0; x < slot.length; x++) {
          if (spinResult[line[x]].id === slot[x]) {
            if (x === 0) {
              iSoundCnt[x] = cal;
            } else {
              iSoundCnt[x] = iSoundCnt[x - 1] + 1;
            }
          } else {
            break;
          }
        }
      });
    });
    return iSoundCnt;
  }

  private calcMaxNumberOfCherry(spinResult: Array<Icon>): number {
    return this.slotConfig.lines.reduce((max, line) => {
      let count = 0;
      for (let i = 0; i < line.length; i++) {
        const symbol = spinResult[line[i]];
        if (symbol.id === SlotId.G) {
          count += 1;
        } else {
          break;
        }
      }
      return Math.max(max, count);
    }, 0);
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.HANDLE_SKIP_HCM_MESSAGE);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult3x3({
      reelPositions: result.bet.result.reelPositions.slice(0, 3),
      reelSet: setSlotConfig().reels.find(
        (reelSet) => reelSet.id === result.bet.reelSet.id,
      )!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 3));
    this.nextResult = result;
    setNextResult(result);
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult.balance.placed,
      );
    }
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(
      this.nextResult!.bet.result.spinResult,
    );
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinsMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      if (isHighChanceMode(setGameMode()) && !setIsFreeSpinsWin()) {
        const hcm = setCurrentHCM();
        if (
          hcm.isActive &&
          hcm.currentRound === hcm.totalRounds &&
          hcm.bonusList.length === 0
        ) {
          this.endHighChanceMode();
        } else {
          const userBonus = hcm.bonusList[0];
          const nextMode = getGameModeByBonusId(userBonus.bonus.id);
          if (nextMode !== setGameMode()) {
            eventManager.emit(EventTypes.CHANGE_MODE, {
              mode: getGameModeByBonusId(userBonus.bonus.id),
              reelSetId: setReelSetId(),
              reelPositions: this.nextResult!.bet.result.reelPositions,
              background: hcm.background,
            });
          }
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus()) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.FSTrigger].duration,
        );
        jingleDelay.addOnStart(() => {
          eventManager.emit(EventTypes.PATLAMP_ANIMATION, PatLampAnimType.X3);
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else if (
        this.calcMaxNumberOfCherry(this.nextResult!.bet.result.spinResult) === 3
      ) {
        const delay = Tween.createDelayAnimation(2000);
        delay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
          eventManager.emit(EventTypes.END_CHERRY_BLINK_ANIMATION);
          eventManager.emit(EventTypes.SHOW_TINT, false);
        });
        delay.start();
      } else {
        const winningDelay = Tween.createDelayAnimation(
          ANNOUNCE_ANIMATION_DURATION,
        );
        winningDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        winningDelay.start();
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin() || this.getFreeSpinBonus()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
